import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@mui/material';

function EditProjectGroup({ onSave, group, isEditing, onClose }) {
  const [groupName, setGroupName] = useState(group ? group.groupName : ''); // Adjusted to use `group.groupName`
  const [groupNumber, setGroupNumber] = useState(group ? group.groupNumber : ''); // Adjusted to use `group.groupNumber`

  useEffect(() => {
    if (group) {
      setGroupName(group.groupName); // Adjusted to use `group.groupName`
      setGroupNumber(group.groupNumber); // Adjusted to use `group.groupNumber`
    }
  }, [group]);

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const handleGroupNumberChange = (e) => {
    setGroupNumber(e.target.value);
  };

  const handleSaveClick = () => {
    onSave({ ...group, groupName: groupName, groupNumber: groupNumber }); // Adjusted keys to match your state
    onClose();
  };





  return (
    <Dialog open={isEditing} onClose={onClose}>
      <DialogTitle>Edit Project Group</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please update the details for the project group.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="groupName"
          label="Group Name"
          fullWidth
          value={groupName}
          onChange={handleGroupNameChange}
        />
        <TextField
          margin="dense"
          id="groupNumber"
          label="Group Number"
          fullWidth
          value={groupNumber}
          onChange={handleGroupNumberChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSaveClick} color="primary">
          Save Changes
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditProjectGroup;