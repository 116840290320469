import React, { useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { createTheme, ThemeProvider, MRT_ActionMenuItem, MenuItem, Box, Button } from '@mui/material';
import { CSVLink } from 'react-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditProjectGroup from './EditProjectGroup';

const theme = createTheme({
  // Customize your theme here
});

function ProjectTable({ drawings, setDrawings, selectedGroupId, onGroupChange, onDeleteRow }) {
  const [editRowData, setEditRowData] = useState(null); // Holds the data of the row being edited


  const handleEditSubmit = async (row) => {
    console.log('Submitting edit:', row);

    const updatedDrawings = drawings.map((drawing) => {
      if (drawing.id === row.id) {
        return { ...drawing, ...row.values };
      }
      return drawing;
    });

    setDrawings(updatedDrawings);
    handleCloseEditDialog(); // Close the dialog

  };


  const handleDeleteRow = (row) => {
    let groupId = row.original.groupId;

    // Define the data to send in the request
    const requestData = {
      groupId: groupId,
    };

    // Define the options for the fetch request
    const requestOptions = {
      method: 'DELETE', // Use DELETE method to remove the group
      headers: {
        'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify(requestData), // Convert requestData to JSON and include it in the request body
    };

    // Make the DELETE request to your server
    fetch('https://penguintools.co.uk/RVT%20Plugins/QRCode/DeleteProjectGroup.php', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('Response from server:', data); // Log the response for debugging

        // Handle the response from the server
        if (data.success) {
          // Successful removal
          console.log('Project group removed successfully');

          // Call the onDeleteRow callback to trigger re-fetching data in App.js
          onDeleteRow(row);
        } else {
          // Error occurred
          console.error('Failed to remove project group:', data.message);
          // Handle the error and provide feedback to the user if necessary
        }
      });
  };

  const handleEditRow = (row) => {
    setEditRowData(row.original); // Set the row to be edited
  };

  const handleCloseEditDialog = () => {
    setEditRowData(null); // Reset the edit row data, which will also close the dialog
  };


  const handleViewDrawingRegister = (row) => {
    console.log('View Drawing Register:', row.original.groupId);
    onGroupChange(row.original.groupId);
  };


  const hiddenColumns = ['groupId', 'mainUserId', 'secondUserId'];

  const columns = React.useMemo(
    () => [
    
      { accessorKey: 'groupId', header: 'Group Id', enableEditing: false },
      { accessorKey: 'groupNumber', header: 'Group Number', muiTableBodyCellEditTextFieldProps: { type: 'text' } },
      { accessorKey: 'groupName', header: 'Group Name' },
      { accessorKey: 'mainUserId', header: 'Main User Id', enableEditing: false, muiTableBodyCellEditTextFieldProps: { type: 'text' } },
      { accessorKey: 'secondUserId', header: 'Secondary User Id', enableEditing: false, muiTableBodyCellEditTextFieldProps: { type: 'text' } },
      {
        accessorKey: 'viewDrawingRegister',
        header: 'View Drawing Register',
        Cell: ({ row }) => {
          // console.log('Rendering View Button for row:', row);
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleViewDrawingRegister(row)}
            >
              View
            </Button>
          );
        },
      },
      {
        accessorKey: 'edit',
        header: 'Edit',
        Cell: ({ row }) => (
          <Button variant="outlined" color="primary" onClick={() => handleEditRow(row.original)}>
            Edit
          </Button>
        ),
        width: 100, // Set a fixed width for the first column
      },
      {
        accessorKey: 'delete',
        header: 'Delete',
        Cell: ({ row }) => (
          <Button
            variant="outlined"
            color="error"
            onClick={() => handleDeleteRow(row)}
          >
            Delete
          </Button>
        ),
        width: 100, // Set a fixed width for the second column
      },
    ].filter((column) => !hiddenColumns.includes(column.accessorKey)),
    [hiddenColumns]
  );
  const headers = [
    { label: 'Group Id', key: 'groupId' },
    { label: 'Group Number', key: 'groupNumber' },
    { label: 'Group Name', key: 'groupName' },
    { label: 'Main User Id', key: 'mainUserId' },
    { label: 'Secondary User Id', key: 'secondUserId' },
  ];

  const dataForCsv = drawings.map((drawing) => ({
    groupId: drawing.projectName,
    groupNumber: drawing.sheetNumber,
    groupName: drawing.sheetName,
    mainUserId: drawing.mainUserId,
    secondUserId: drawing.revisionDescription,
  }));

  const tableOptions = {
    initialState: {
      density: 'compact',
    },
    muiTableContainerProps: {
      sx: { boxShadow: 2 },
    },
    resizableColumns: true,
  };

  return (
    <ThemeProvider theme={theme}>
      <Box margin="0 50px" maxWidth="calc(100% - 100px)">
        <MaterialReactTable columns={columns} data={drawings} {...tableOptions} />
        {editRowData && (
          <EditProjectGroup
            group={editRowData}
            onSave={handleEditSubmit}
            isEditing={Boolean(editRowData)} // This ensures the dialog opens when `editRowData` is not null
            onClose={handleCloseEditDialog}
          />
        )}
      </Box>
      <CSVLink
        data={dataForCsv}
        headers={headers}
        filename={'drawings.csv'}
        className="btn btn-primary"
        target="_blank"
      >
        <Button startIcon={<FileDownloadIcon />}>
          Export CSV
        </Button>
      </CSVLink>
    </ThemeProvider>
  );
}

export default ProjectTable;