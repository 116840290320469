// Menu.js
import React from 'react';
import './Menu.css';

function Menu({ onLogout }) {
  const handleLogout = () => {
    // Clear user session and perform any other necessary cleanup
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('userId');
    onLogout();
  };

  return (
    <nav className="menu-container">
      <ul>
        <li><a href="/">Projects</a></li>
        <li><a href="https://www.penguintools.co.uk/product/qr-code-tokens/" target="_blank">Pricing</a></li>
        <li><a href="https://www.penguintools.co.uk/downloads/" target="_blank">Downloads</a></li>
        <li><a href="#" onClick={handleLogout}>Logout</a></li> {/* Add an onClick event for logout */}
      </ul>
    </nav>
  );
}

export default Menu;
