// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Table from './DataTable/Table';
import ProjectTable from './ProjectTable/ProjectTable';
import Login from './login';
import Menu from './Menu';
import AddProjectGroup from './AddProjectGroup';

function App() {
  const [drawings, setDrawings] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loggedIn, setLoggedIn] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  useEffect(() => {
    const storedLoggedIn = localStorage.getItem('loggedIn');
    const storedUserId = localStorage.getItem('userId');

    if (storedLoggedIn === 'true' && storedUserId) {
      setLoggedIn(true);
      setUserId(storedUserId);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      RetrieveSQLTable();
      // RetrieveDrawingsRegister();
    }
  }, [userId]);
  useEffect(() => {
    if (selectedGroupId) {
      RetrieveDrawingsRegister(selectedGroupId);
    }
  }, [selectedGroupId]);


  const handleDeleteRow = (row) => {
    let groupId = row.original.groupId;

    // Define the data to send in the request
    const requestData = {
      groupId: groupId,
    };

    // Define the options for the fetch request
    const requestOptions = {
      method: 'DELETE', // Use DELETE method to remove the group
      headers: {
        'Content-Type': 'application/json', // Set the content type to JSON
      },
      body: JSON.stringify(requestData), // Convert requestData to JSON and include it in the request body
    };

    // Make the DELETE request to your server
    fetch('https://penguintools.co.uk/RVT%20Plugins/QRCode/DeleteProjectGroup.php', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('Response from server:', data); // Log the response for debugging

        // Handle the response from the server
        if (data.success) {
          // Successful removal
          console.log('Project group removed successfully');

          // Call the callback function to re-fetch data
          handleCreateProjectGroup();
        } else {
          // Error occurred
          console.error('Failed to remove project group:', data.message);
          // Handle the error and provide feedback to the user if necessary
        }
      });
  };




  function RetrieveSQLTable() {

    const url = `https://penguintools.co.uk/RVT%20Plugins/QRCode/GetProjects.php?userId=${userId}`;

    fetch(url)
      .then(response => {
        if (!response.ok) {
          throw new Error('Server responded with an error');
        }
        return response.text();
      })
      .then(text => {
        try {
          return JSON.parse(text);
        } catch (err) {
          throw new Error('Failed to parse JSON');
        }
      })
      .then(responseData => {
        setGroups(responseData);
        console.log("Data fetched:", responseData);
        console.log("User Id:", userId);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }

  const handleGroupChange = (groupId) => {
    setSelectedGroupId(groupId);
    console.log("Selected Group ID:", groupId); // Add this line to check the update
    RetrieveDrawingsRegister(groupId);
  };

  function RetrieveDrawingsRegister(groupId) {
    if (!groupId) {
      setDrawings([]);
      return;
    }

    fetch(`https://penguintools.co.uk/RVT%20Plugins/QRCode/GetTableData-Project.php?Group_Id=${groupId}`)
      .then((response) => {
        if (!response.ok) {
          // Convert non-2xx HTTP responses into errors
          return response.json().then((data) => {
            throw new Error(data.error || 'Server responded with an error');
          });
        }
        return response.json();
      })
      .then((responseData) => {
        setDrawings(responseData);
        console.log("Data fetched:", responseData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }

  const handleCreateProjectGroup = () => {
    // Call any necessary functions or API endpoints to refresh your project groups
    RetrieveSQLTable();
  };

  const handleLogout = () => {
    console.log('Logout successful');
    setLoggedIn(false);
    setUserId(null);
    localStorage.removeItem('loggedIn');
    localStorage.removeItem('userId');
  };

  const handleLoginSuccess = (id) => {
    console.log('Login successful: ' + id);
    setUserId(id);
    setLoggedIn(true);
    localStorage.setItem('loggedIn', true);
    localStorage.setItem('userId', id);
  };


  console.log('Rendering App component. loggedIn:', loggedIn);

  return (
    <div className="App">
      {loggedIn && <Menu onLogout={handleLogout} />}
      {loggedIn ? (
        <>
          <AddProjectGroup onCreate={handleCreateProjectGroup} userId={userId} />
          <ProjectTable
        drawings={groups}
        selectedGroupId={selectedGroupId}
        onGroupChange={handleGroupChange}
        onDeleteRow={handleDeleteRow}
      />
          <Table key={selectedGroupId} drawings={drawings} />
        </>
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );

}


export default App;
