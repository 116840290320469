import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import { createTheme, ThemeProvider, Box, TextField, Button } from '@mui/material';
import { CSVLink } from 'react-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';


const theme = createTheme({
  // Customize your theme here
});



function Table({ drawings, setDrawings }) {

  
  const handleEditSubmit = async (row) => {
    // Here, you would send the updated row data to your backend
    // For demonstration, we'll just log it and update the state
    console.log('Submitting edit:', row);

    // Update the local state to reflect the changes
    const updatedDrawings = drawings.map((drawing) => {
      if (drawing.id === row.id) {
        return { ...drawing, ...row.values };
      }
      return drawing;
    });

    setDrawings(updatedDrawings);
  };

  const hiddenColumns = ['tokenId', 'User_ID', 'Group_Id'];


  const columns = React.useMemo(
    () => [
      { accessorKey: 'projectName', header: 'Project Name' }, // Non-editable
      { accessorKey: 'sheetNumber', header: 'Sheet Number', enableEditing: false, muiTableBodyCellEditTextFieldProps: { type: 'text' } }, // Editable
      { accessorKey: 'sheetName', header: 'Sheet Name', enableEditing: true }, // Non-editable
      { accessorKey: 'currentRevision', header: 'Current Revision', muiTableBodyCellEditTextFieldProps: { type: 'text' } }, // Editable
      { accessorKey: 'revisionDescription', header: 'Revision Description' }, // Non-editable
      { accessorKey: 'drawnBy', header: 'Drawn By', muiTableBodyCellEditTextFieldProps: { type: 'text' } }, // Editable
      { accessorKey: 'Date_Time', header: 'Date', enableEditing: false },
      { accessorKey: 'tokenId', header: 'Token Id', enableEditing: false },
      { accessorKey: 'history', header: 'History', enableEditing: false },
      { accessorKey: 'url', header: 'URL' },
      { accessorKey: 'User_ID', header: 'User ID', enableEditing: false },
      { accessorKey: 'Group_Id', header: 'Group ID', enableEditing: false },
    ].filter((column) => !hiddenColumns.includes(column.accessorKey)),
    [hiddenColumns]
  );

  const headers = [
    { label: 'Project Name', key: 'projectName' },
    { label: 'Sheet Number', key: 'sheetNumber' },
    { label: 'Sheet Name', key: 'sheetName' },
    { label: 'Current Revision', key: 'currentRevision' },
    { label: 'Revision Description', key: 'revisionDescription' },
    { label: 'Drawn By', key: 'drawnBy' },
    { label: 'Date', key: 'Date_Time' },
    { label: 'Token Id', key: 'tokenId' },
    { label: 'History', key: 'history' },
    { label: 'URL', key: 'url' },
    { label: 'User_ID', key: 'userId' },
    { label: 'Group ID', key: 'Group_Id' },
  ];

  const dataForCsv = drawings.map((drawing) => ({
    projectName: drawing.projectName,
    sheetNumber: drawing.sheetNumber,
    sheetName: drawing.sheetName,
    currentRevision: drawing.currentRevision,
    revisionDescription: drawing.revisionDescription,
    drawnBy: drawing.drawnBy,
    Date_Time: drawing.Date_Time,
    tokenId: drawing.tokenId,
    history: drawing.history,
    url: drawing.url,
    userId: drawing.userId,
    Group_Id: drawing.Group_Id,
  }));

  const tableOptions = {
    initialState: {
      density: 'compact',
    },
    enableEditing: true,
    enableRowActions: true,
    onEditingRowSave: handleEditSubmit,
    muiTableContainerProps: {
      sx: { boxShadow: 2 },
    },
  };


  return (
    <ThemeProvider theme={theme}>
      <Box margin="0 50px" maxWidth="calc(100% - 40px)">
        <MaterialReactTable columns={columns} data={drawings} {...tableOptions} />
      </Box>


      <CSVLink
        data={dataForCsv}
        headers={headers}
        filename={'drawings.csv'}
        className="btn btn-primary"
        target="_blank"
      >
        <Button
          startIcon={<FileDownloadIcon />}
        >
          Export CSV
        </Button>
      </CSVLink>
    </ThemeProvider>
  );
}

export default Table;