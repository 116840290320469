import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';

function AddProjectGroup({ onCreate, userId }) {
  const [open, setOpen] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [groupNumber, setGroupNumber] = useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setGroupName('');
    setGroupNumber('');
  };

  const handleGroupNameChange = (e) => {
    setGroupName(e.target.value);
  };

  const handleGroupNumberChange = (e) => {
    setGroupNumber(e.target.value);
  };

  const handleCreateClick = () => {
    if (groupName.trim() === '' || groupNumber.trim() === '') {
      alert('Please enter both Group Name and Group Number');
      return;
    }

    console.log('Creating new project group:', groupName, groupNumber, userId);
    // Make an API call to create a new project group
    fetch('https://penguintools.co.uk/RVT%20Plugins/QRCode/NewProjectGroup.php?userId= ' + userId, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ groupName, groupNumber, userId }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          // Call the callback function to notify the parent component
          onCreate();
          handleClose();
        } else {
          alert('Failed to create a new project group: ' + data.message);
        }
      })
      .catch((error) => {
        console.error('Error creating a new project group:', error);
      });
  };
  
  

  return (
    <div style={{ margin: '10px 50px' }}>
    <Button size="small" variant="outlined" color="primary" onClick={handleOpen}>
        Add New Project Group
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add New Project Group</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the details for the new project group.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="groupName"
            label="Group Name"
            fullWidth
            value={groupName}
            onChange={handleGroupNameChange}
          />
          <TextField
            margin="dense"
            id="groupNumber"
            label="Group Number"
            fullWidth
            value={groupNumber}
            onChange={handleGroupNumberChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateClick} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AddProjectGroup;
